function getCode() {
  return document.getElementById('js-barcode-manually-code').value;
}

document.addEventListener('DOMContentLoaded', () => {
  const button = document.getElementById('js-barcode-manually-submit');
  if (!button) return;

  button.addEventListener('click', () => {
    const code = getCode();
    if (!code) {
      alert('製造指示番号を入力してください');
      return;
    }

    button.setAttribute('disabled', true);
    window.location.href = `${window.redirectToUrl}?code=${code}`;
  });
});
