import Quagga from "./quagga.min";
import _ from "lodash";

document.addEventListener("DOMContentLoaded", () => {
  const canvasElement = document.querySelector("#interactive");
  if (!canvasElement) {
    return;
  }

  Quagga.init(
    {
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: canvasElement,
        constraints: {
          width: 1280,
          height: 720,
          facingMode: "environment",
        },
        area: {
          //必要ならバーコードの読み取り範囲を調整できる（この場合は、上30%/下30%は読み取りしない）
          top: "30%",
          right: "0%",
          left: "0%",
          bottom: "30%",
        },
      },
      locator: {
        patchSize: "medium",
        halfSample: true,
      },
      numOfWorkers: 2,
      decoder: {
        // readers: ['ean_reader']//ISBNは基本的にこれ（他にも種類あり）
        readers: ["code_39_reader"],
        // readers: ['code_39_vin_reader']
      },
      locate: true,
    },
    (err) => {
      if (!err) {
        Quagga.start();

        Quagga.onProcessed(function (result) {
          const drawingCtx = Quagga.canvas.ctx.overlay;
          const drawingCanvas = Quagga.canvas.dom.overlay;

          if (!result) return;

          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute("width")),
              parseInt(drawingCanvas.getAttribute("height"))
            );
            result.boxes
              .filter(function (box) {
                return box !== result.box;
              })
              .forEach(function (box) {
                Quagga.ImageDebug.drawPath(
                  box,
                  {
                    x: 0,
                    y: 1,
                  },
                  drawingCtx,
                  {
                    color: "green",
                    lineWidth: 1,
                  }
                );
              });
          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(
              result.box,
              {
                x: 0,
                y: 1,
              },
              drawingCtx,
              {
                color: "blue",
                lineWidth: 1,
              }
            );
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
              result.line,
              {
                x: "x",
                y: "y",
              },
              drawingCtx,
              {
                color: "red",
                lineWidth: 2,
              }
            );
          }
        });

        Quagga.onDetected(
          _.debounce(
            function (success) {
              const code = success.codeResult.code;
              if (isValidBarcode(code)) {
                const codeBody = code.substr(1);
                const encodedCode = encodeURI(codeBody);
                window.location.href = redirectToEndpoint(encodedCode);
              } else {
                alert(code + "は正しいバーコードではありません。");
              }
            },
            100,
            { maxWait: 100 }
          )
        );

        const isValidBarcode = (code) => {
          return code.match(/^\%\d+$/) ? true : false;
        };

        const redirectToEndpoint = (code) => {
          const urlBase = window.redirectToUrl || '/result';
          return `${urlBase}?code=${code}`;
        };
      }
    }
  );

  // const calc = isbn => {
  //   const arrIsbn = isbn
  //     .toString()
  //     .split("")
  //     .map(num => parseInt(num));
  //   let remainder = 0;
  //   const checkDigit = arrIsbn.pop();
  //
  //   arrIsbn.forEach((num, index) => {
  //     remainder += num * (index % 2 === 0 ? 1 : 3);
  //   });
  //   remainder %= 10;
  //   remainder = remainder === 0 ? 0 : 10 - remainder;
  //
  //   return checkDigit === remainder;
  // }
});
